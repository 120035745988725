<template>

<div class="delete" :class="{'is-loading': is.loading}">

	<div class="clash">

		<app-icon icon="clash" class="clash-icon" />

		<div class="clash-title">Oops, looks like you're already busy at this time!</div>

		<div class="clash-events">

			<p>This event clashes with the following scheduled events you have. Do you want to cancel them and proceed with joining this one?</p>

			<div class="clash-events-item" v-for="(event, index) in clashes" :key="index">

				<div class="clash-events-item-name">{{ event.name }}</div>
				<div class="clash-events-item-time">{{ event.date | formatDate('dddd') }}, {{ event.date | formatDate($hourFormat) }} - {{ (event.date + event.duration) | formatDate($hourFormat) }}</div>

			</div>

		</div>

		<div class="clash-buttons">

			<app-button theme="red" text="Confirm" :loading="is.confirming" v-on:click="onConfirmClick" />
			<app-button theme="grey" text="Nevermind" v-on:click="onCancelClick" />

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			is: {
				loading: true,
				confirming: false
			},
			clashes: []
		}

	},

	created: function() {

		this.load();

	},

	methods: {

		onConfirmClick: function() {

			this.is.confirming = true

			this.$api.post('convention/schedule/event/' + this.$route.params.id + '/join').then(function() {

				this.$router.push({
					name: 'Convention.Schedule.Event'
				})

			}.bind(this))

		},

		onCancelClick: function() {

			this.$router.push({
				name: 'Convention.Schedule.Event'
			})

		},

		load: function() {

			this.$api.get('convention/schedule/event/' + this.$route.params.id + '/join').then(function(json) {

				this.clashes = json.clashes

				this.is.loading = false

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.delete {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	color: #4faa80;
}

.clash {
	text-align: center;
	max-width: 600px;
	width: 100%;
	padding: 0px 10px;
}

.clash-icon {
	font-size: 196px;
	color: #c55b5b;
	margin-bottom: 20px;
}

.is-device .clash-icon {
	font-size: 128px;
}

.clash-title {
	font-size: 24px;
	color: #c55b5b;
	font-weight: 400;
}

.clash-events {
	margin-top: 20px;
	font-size: 16px;
	color: #707070;
	line-height: 20px;
}

.clash-events-item {
	border: 1px solid #eee;
	background-color: #fff;
	border-radius: 4px;
	margin-top: 10px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
}

.clash-events-item-name {
	font-size: 16px;
	font-weight: 500;
	color: #707070;
}

.clash-events-item-time {
	font-size: 16px;
	font-weight: 300;
	color: #707070;
}

.clash-buttons {
	display: flex;
	margin-top: 40px;
	justify-content: center;
}

.clash-buttons >>> .button {
	margin: 0px 5px;
}

</style>
